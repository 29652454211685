








































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, State as StateClass } from 'vuex-class';
import to from 'await-to-js';
import json2csv from 'json2csv/dist/json2csv.umd';
import FileSaver from 'file-saver';
import slugify from 'slugify';
import BigNumber from 'bignumber.js';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { State } from '@/models/State';
import { Asset } from '@/models/assets/Asset';
import { ManagerRole } from '@/models/manager/Manager';
import DeleteAssetModal from '@/components/assets/DeleteAssetModal.vue';
import { bloqifyFirestore } from '@/boot/firebase';

@Component({
  components: {
    DeleteAssetModal,
  },
})
export default class AssetsTable extends Vue {
  options = {
    headings: {
      name: 'Fund',
      sold: 'Sold',
      sharesAvailable: 'Available',
      totalValueShares: 'Supply',
      totalEuroInvested: 'Total invested (EUR)',
      euroMin: 'Minimum investment',
      sharePrice: 'Share price',
      published: 'Published',
      modify: '',
      export: '',
      dropdown: '',
    },
    filterable: ['name', 'sharesAvailable', 'totalValueShares', 'euroMin', 'sharePrice', 'published'],
    // columnsClasses strings need to have a space at the end
    // because vue-tables-2-premium adds classes runtime without a space before
    columnsClasses: {
      name: 'table__col--name align-middle table__col--l ',
      sold: 'table__col--sold align-middle table__col--xs ',
      sharesAvailable: 'table__col--sharesAvailable align-middle table__col--s ',
      totalValueShares: 'table__col--totalValueShares align-middle table__col--xs ',
      totalEuroInvested: 'table__col--totalEuroInvested align-middle table__col--s ',
      euroMin: 'table__col--euroMin align-middle table__col--s ',
      sharePrice: 'table__col--sharePrice align-middle table__col--s ',
      published: 'table__col--published align-middle table__col--s ',
      modify: 'table__col--modify align-middle table__col--s ',
      dropdown: 'table__col--dropdown align-middle table__col--xxs ',
      export: 'table__col--dropdown align-middle table__col--l ',
    },
    skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table
  };

  showDeleteModal: boolean = false;
  selectedAsset: any = null;

  @Getter getCurrentManagerRole!: ManagerRole;

  @StateClass boundAssets!: State['boundAssets'];
  @StateClass openPayments!: State['openPayments'];
  @Action bindFirestoreReferences!: Function;
  @Action(addToastMessage) addToastMessage!: Function;

  get columns(): string[] {
    const columns = ['name', 'sold', 'sharesAvailable', 'totalValueShares', 'totalEuroInvested', 'euroMin', 'sharePrice', 'published', 'export'];
    if (this.allowModification) {
      columns.push('modify');
      columns.push('dropdown');
    }
    return columns;
  }

  /**
   * Returns assets extended with the openPaymentAmount field.
   */
  get assets(): Asset[] {
    return this.boundAssets?.map((asset) => ({
      ...asset,
      id: asset.id,
      openPaymentAmount: this.openPayments.filter(({ asset: paymentAsset }) => paymentAsset.id === asset.id).length,
      openPaymentTotalShares: this.openPayments.filter(({ asset: paymentAsset }) => paymentAsset.id === asset.id).reduce(
        (accumulator, payment) => accumulator + payment.providerData.metadata.sharesAmount,
        0,
      ),
    })) || [];
  }

  /**
   * Returns assets count.
   */
  get assetCount(): number {
    return this.assets.length;
  }

  /**
   * Show asset modification modal.
   */
  get allowModification(): boolean {
    return this.getCurrentManagerRole === ManagerRole.Superadmin || this.getCurrentManagerRole === ManagerRole.Admin;
  }

  getAssetSold(asset: Asset): number {
    // @ts-ignore
    return new BigNumber(asset.totalValueShares).minus(asset.sharesAvailable).minus(asset.openPaymentTotalShares).toNumber();
  }

  handleModal(asset: Asset): void {
    this.selectedAsset = asset;
    this.showDeleteModal = true;
  }

  /**
   * Returns whether current browser allows file downloads.
   */
  get fileDownloadIsSupported(): boolean {
    try {
      return !!new Blob();
    } catch (e) {
      return false;
    }
  }

  /**
   * Execute fund investors download.
   */
  async downloadFundInvestorsCsv(asset: Asset): Promise<void> {
    const assetRef = bloqifyFirestore.collection('assets').doc(asset.id);

    const [getInvestmentsError, getInvestmentsSuccess] = await to(
      bloqifyFirestore.collection('investments').where('asset', '==', assetRef).get(),
    );

    if (getInvestmentsError) {
      this.addToastMessage({
        text: 'Something went wrong while fetching the investments for the selected asset',
        type: 'danger',
      });
      return;
    }

    const investors = await Promise.all(getInvestmentsSuccess!.docs.map(async (doc) => {
      const [getInvestorError, getInvestorSuccess] = await to(
        bloqifyFirestore.collection('investors').doc(doc.get('investor').id).get(),
      );

      if (getInvestorError) {
        this.addToastMessage({
          text: 'Something went wrong while fetching the investors for the selected asset',
          type: 'danger',
        });
      }

      return {
        id: getInvestorSuccess!.get('customId'),
        name: getInvestorSuccess!.get('name'),
        surname: getInvestorSuccess!.get('surname'),
        email: getInvestorSuccess!.get('email'),
        boughtSharesTotal: doc.get('boughtSharesTotal'),
        paidEuroTotal: doc.get('paidEuroTotal'),
      };
    }));

    const BOM = '\uFEFF';
    const csv = BOM + json2csv.parse(investors);

    // Present file download
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const fundNameSlug: string = slugify(asset.name.en);
    FileSaver.saveAs(blob, `${fundNameSlug}-investors.csv`);
  }
}
